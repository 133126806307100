const baseUrl = "Enums";
export const getAdmissionTypesUrl = () => `${baseUrl}/AdmissionTypes`;
export const getBloodGroupsUrl = () => `${baseUrl}/BloodGroups`;
export const getGenoTypesUrl = () => `${baseUrl}/GenoTypes`;
export const getMedicalRecordsUrl = () => `${baseUrl}/MedicalRecords`;
export const getRelationshipsUrl = () => `${baseUrl}/Relationships`;
export const getSponsorRelationshipsUrl = () =>
	`${baseUrl}/SponsorRelationships`;
export const getReligionsUrl = () => `${baseUrl}/Religions`;
export const getStudentModesUrl = () => `${baseUrl}/StudentModes`;
export const getStudentModesOfStudyUrl = () => `${baseUrl}/StudentModesOfStudy`;
export const getStudentModeOfEntryUrl = () => `${baseUrl}/StudentModeOfEntry`;
export const getCourseTypes = () => `${baseUrl}/CourseType`;
export const getUnitLoads = () => `${baseUrl}/UnitLoad`;
export const getStudentTypesUrl = () => `${baseUrl}/studenttype`;
export const getSchoolFeesPaymentTypesUrl = () =>
	`${baseUrl}/schoolFeesPaymentTypes`;
export const getPaymentPurposesUrl = () => `${baseUrl}/payment-purpose`;
export const getGendersUrl = () => `${baseUrl}/Genders`;
export const getMaritalStatusesUrl = () => `${baseUrl}/MaritalStatus`;
export const getOlevelGradeUrl = () => `${baseUrl}/O-level-Grade`;
export const getPGStudentTypesUrl = () => `${baseUrl}/pg-student-types`;
export const getPGYearOfStudyUrl = () => `${baseUrl}/pg-year-of-study`;
export const getOlevelCertificateTypesUrl = () =>
	`${baseUrl}/Upload-Document-Types`;
export const getAllCampusesUrl = () => `${baseUrl}/campuses`;
export const getPGActualProgrammesUrl = () => `${baseUrl}/pg-programmes`;
export const getUserTypesUrl = () => `${baseUrl}/user-types`;
export const getGroupSelectionsUrl = () => `${baseUrl}/group-selection`;
export const getBusaryAccountsUrl = () => `${baseUrl}/account-number-groups`;
export const getProgrammeTypesUrl = () => `${baseUrl}/programme-types`;
