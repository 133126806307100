import { generateUrlParams } from "../../utils/generateUrlParams";

const baseUrl = "admin/course-management";
export const postCourseSingUploadUrl = () => `${baseUrl}/courses`;
export const downloadCourseSampleUrl = () =>
	`${baseUrl}/download-excel-template`;
export const postCourseBulkUUrl = () => `${baseUrl}/courses-bulk-upload`;
export const editUploadedCourseUrl = (courseId) =>
	`${baseUrl}/courses/${courseId}`;
export const getCoursesToManageUrl = (filter) =>
	`${baseUrl}/courses?${generateUrlParams(filter)}`;
export const assignCoursesToDeptsUrl = () =>
	`${baseUrl}/assign-courses-to-dept`;
export const getCoursesAssignedToDeptsUrl = (filter) =>
	`${baseUrl}/assign-courses-to-dept?${generateUrlParams(filter)}`;
export const getUnitLoadsToManageUrl = (filter) =>
	`${baseUrl}/manage-unit-loads?${generateUrlParams(filter)}`;
export const editCourseAssignedToDeptsUrl = (courseId) =>
	`${baseUrl}/assign-courses-to-dept/${courseId}`;
export const toggleCourseAssignedActivationUrl = (id) =>
	`${baseUrl}/assign-courses-to-dept/${id}/toggle-activation`;
export const editUnitLoadsToManageUrl = (unitLoadId) =>
	`${baseUrl}/manage-unit-loads/${unitLoadId}`;
export const getCourseRegsToToggleUrl = (filter) =>
	`${baseUrl}/open-or-close-course-reg?${generateUrlParams(filter)}`;
export const toggleCourseRegistrationOpenCloseUrl = (id) =>
	`${baseUrl}/open-or-close-course-reg/${id}/toggle-activation`;
export const getAllowableUnitsForStudentUrl = (filter) =>
	`${baseUrl}/student-allowable-unit-load?${generateUrlParams(filter)}`;
export const editAllowableUnitsForStudentUrl = (matricNo) =>
	`${baseUrl}/student-allowable-unit-load/${matricNo}`;
export const getCoursesToAddOrDropUrl = (filter) =>
	`${baseUrl}/add-or-drop-courses?${generateUrlParams(filter)}`;
export const getRegistrableCoursesUrl = (filter) =>
	`${baseUrl}/add-or-drop-courses/registrable-courses?${generateUrlParams(
		filter
	)}`;
export const addCoursesUrl = () => `${baseUrl}/add-or-drop-courses/add-courses`;
export const dropCourseUrl = (courseId) =>
	`${baseUrl}/add-or-drop-courses/drop-courses/${courseId}`;
export const getCourseApprovalUrl = (filter) =>
	`${baseUrl}/course-approval?${generateUrlParams(filter)}`;
export const geRegisteredCoursesForApprovalUrl = (filter) =>
	`${baseUrl}/course-approval/registered-courses?${generateUrlParams(
		filter
	)}`;
export const postCourseApprovalUrl = () => `${baseUrl}/course-approval`;
export const getCourseApporvalRegisteredCoursesUrl = (filter) =>
	`${baseUrl}/course-approval/registered-courses?${generateUrlParams(
		filter
	)}`;
export const getLecturerCourses = (filter) =>
	`${baseUrl}/view-lecturer-courses?${generateUrlParams(filter)}`;

export const postLecturerCourseUpdate = () =>
	`${baseUrl}/lecturer-course-update`;

export const editAssignCourseToLecturer = (lecturerId) =>
	`${baseUrl}/assign-courses-to-lecturer/${lecturerId}`;

export const downloadStudentUnitLoadSampleUrl = () =>
	`${baseUrl}/download-student-unit-load-sample-sheet`;

export const uploadBulkStudentUnitLoadUrl = () =>
	`${baseUrl}/upload-students-unit-load`;

export const cloneCourseAssignmentUrl = () =>
	`${baseUrl}/clone-course-assignment`;
